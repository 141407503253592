<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form
          :inline="true"
          ref="selectForm"
          :model="selectForm"
          class="select_form"
        >
          <el-form-item label="姓名:">
            <el-input v-model="selectForm.name" placeholder="姓名"></el-input>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="calc-height">
      <!--列表-->
      <el-table
        :data="users"
        highlight-current-row
        height="100%"
    
        @current-change="selectCurrentRow"
        @row-dblclick="handleShow"
        class="new_table"
      >
        <el-table-column prop="UserName" label="姓名"> </el-table-column>
        <el-table-column label="公司" prop="Factory"></el-table-column>
        <el-table-column label="部门" prop="Dpt"></el-table-column>
        <el-table-column label="岗位" prop="Post"></el-table-column>
        <el-table-column
          label="离职时间"
          prop="DimissionDate"
          :formatter="formatStartTime"
        ></el-table-column>
        <el-table-column
          label="聘用时间"
          prop="Hiredate"
          :formatter="formatStartTime"
        ></el-table-column>
        <el-table-column label="离职类型" prop="Type"></el-table-column>
        <el-table-column
          label="员工性质"
          prop="ContractNature"
        ></el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper ,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>
    <el-dialog
      :title="operation ? '添加' : '编辑'"
      :visible.sync="SakaryVisible"
      v-model="SakaryVisible"
      :close-on-click-modal="false"
      width="70%"
    >
      <el-form
        ref="SakaryForm"
        :model="SakaryForm"
        class="demo-form-inline"
        label-width="150px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="姓名:"
              prop="UserName"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                :disabled="true"
                v-model="SakaryForm.UserName"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="chooseUser"
                  v-if="operation"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="公司:"
              prop="Factory"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input type="text" v-model="SakaryForm.Factory" disabled>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="部门:"
              prop="Dpt"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input type="text" v-model="SakaryForm.Dpt" disabled>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="岗位:"
              prop="Post"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input type="text" :disabled="true" v-model="SakaryForm.Post">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入职日期:" prop="Hiredate">
              <el-date-picker
                type="date"
                v-model="SakaryForm.Hiredate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                :disabled="true"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话:" prop="MobilePhone">
              <el-input
                type="text"
                v-model="SakaryForm.MobilePhone"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="员工性质:" prop="ContractNature">
              <el-input
                type="text"
                v-model="SakaryForm.ContractNature"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="合同开始日期:">
              <el-date-picker
                type="date"
                v-model="SakaryForm.ContractSatr"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                :disabled="true"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="合同结束日期:">
              <el-date-picker
                type="date"
                v-model="SakaryForm.ContractEnd"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                :disabled="true"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="合同情况:"
              prop="ContractState"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="SakaryForm.ContractState"
                placeholder="请选择"
                style="“width: 100%”"
              >
                <el-option label="办理解除" value="办理解除"></el-option>
                <el-option label="未办理解除" value="未办理解除"></el-option>
                <el-option label="自动解除" value="自动解除"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 20px"
          ><div class="tableTitle">
            <span class="midText">分公司财务</span>
          </div></el-row
        >
        <el-row>
          <el-col :span="8">
            <el-form-item label="公积金缴存:" prop="HouseFundsState">
              <el-switch v-model="SakaryForm.HouseFundsState"> </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="公积金缴存开始日期:"
              prop="HouseFundsStartDate"
            >
              <el-date-picker
                type="date"
                v-model="SakaryForm.HouseFundsStartDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="公积金缴存结束日期:" prop="HouseFundsEndDate">
              <el-date-picker
                type="date"
                v-model="SakaryForm.HouseFundsEndDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="个人欠款:"
              prop="PersonDebt"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input type="text" v-model="SakaryForm.PersonDebt"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="缴存时长:" prop="HouseFundsDate">
              <el-input type="text" v-model="SakaryForm.HouseFundsDate">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="扣回公积金费用:" prop="HouseFundsCharge">
              <el-input type="text" v-model="SakaryForm.HouseFundsCharge">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="社保缴纳:" prop="InsuranceState">
              <el-switch v-model="SakaryForm.InsuranceState"> </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="社保减员日期:" prop="InsuranceReduceDate">
              <el-date-picker
                type="date"
                v-model="SakaryForm.InsuranceReduceDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="扣回社保费用:" prop="InsuranceCharge">
              <el-input
                type="text"
                v-model="SakaryForm.InsuranceCharge"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 20px"
          ><div class="tableTitle">
            <span class="midText">分工公司人事</span>
          </div></el-row
        >
        <el-row>
          <el-col :span="8">
            <el-form-item label="离职类型:" prop="Type">
              <el-select
                v-model="SakaryForm.Type"
                placeholder="请选择"
                style="“width: 100%”"
              >
                <el-option label="辞职" value="辞职"></el-option>
                <el-option label="辞退" value="辞退"></el-option>
                <el-option label="异常离职" value="异常离职"></el-option>
                <el-option label="自动离职" value="自动离职"></el-option>
                <el-option label="特殊人员" value="特殊人员"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="离职种类:" prop="LeaveCategory">
              <el-select
                v-model="SakaryForm.LeaveCategory"
                placeholder="请选择"
                style="“width: 100%”"
              >
                <el-option label="旷工" value="旷工"></el-option>
                <el-option label="劝退" value="劝退"></el-option>
                <el-option label="身体原因" value="身体原因"></el-option>
                <el-option label="家庭原因" value="家庭原因"></el-option>
                <el-option label="个人发展" value="个人发展"></el-option>
                <el-option label="待遇问题" value="待遇问题"></el-option>
                <el-option label="工作原因" value="工作原因"></el-option>
                <el-option
                  label="组织结构调整"
                  value="组织结构调整"
                ></el-option>
                <el-option label="即入即离" value="即入即离"></el-option>
                <el-option label="退休" value="退休"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="离职日期:" prop="DimissionDate">
              <el-date-picker
                type="date"
                v-model="SakaryForm.DimissionDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="异常:" prop="UnusualState">
              <el-switch v-model="SakaryForm.UnusualState"> </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="异常情况说明:" prop="UnusualReason">
              <el-input
                type="text"
                v-model="SakaryForm.UnusualReason"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="离职原因:" prop="LeaveReason">
              <el-input type="text" v-model="SakaryForm.LeaveReason"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 20px"
          ><div class="tableTitle">
            <span class="midText">集团人力资源部</span>
          </div></el-row
        >
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="职工保险、公积金等交接:"
              prop="HouseFundsExplain"
            >
              <el-input
                type="text"
                v-model="SakaryForm.HouseFundsExplain"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="劳动/雇佣合同解除手续:" prop="ContractExplain">
              <el-input
                type="text"
                v-model="SakaryForm.ContractExplain"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="培训费用、党员关系:" prop="TrainExplain">
              <el-input
                type="text"
                v-model="SakaryForm.TrainExplain"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="证书名称:" prop="CertificateName">
              <el-input
                type="text"
                v-model="SakaryForm.CertificateName"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="证书费用:" prop="CertificateCharge">
              <el-input
                type="text"
                v-model="SakaryForm.CertificateCharge"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="禁用账号:" prop="AccountState">
              <el-switch v-model="SakaryForm.AccountState"> </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 20px"
          ><div class="tableTitle">
            <span class="midText">集团信息化部</span>
          </div></el-row
        >
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="通讯工具交接及费用结算:"
              prop="PhoneChargeState"
            >
              <el-switch v-model="SakaryForm.PhoneChargeState"> </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 20px"
          ><div class="tableTitle">
            <span class="midText">集团综合办</span>
          </div></el-row
        >
        <el-row>
          <el-col :span="8">
            <el-form-item label="宿舍物品押金:" prop="DormCharge">
              <el-input type="text" v-model="SakaryForm.DormCharge"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="工装情况:" prop="ClotheCharge">
              <el-input
                type="text"
                v-model="SakaryForm.ClotheCharge"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="个人相关证书:" prop="CertificatePerson">
              <el-input
                type="text"
                v-model="SakaryForm.CertificatePerson"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="劳动合同及保险相关资料:" prop="ContractData">
              <el-input
                type="text"
                v-model="SakaryForm.ContractData"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="借阅图书、文件:" prop="FileCharge">
              <el-input type="text" v-model="SakaryForm.FileCharge"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="户籍、计生关系转出情况:"
              prop="RelationExplain"
            >
              <el-input
                type="text"
                v-model="SakaryForm.RelationExplain"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 20px"
          ><div class="tableTitle">
            <span class="midText">财务结算中心</span>
          </div></el-row
        >
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="保险欠款、个人借款、业务相关账务:"
              prop="FinancialExplain"
            >
              <el-input
                type="text"
                v-model="SakaryForm.FinancialExplain"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="扣除欠款金额:" prop="DebtSum">
              <el-input type="text" v-model="SakaryForm.DebtSum"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <upload-files
          :files="SakaryForm.FileArry"
          :key="key"
          action="/cyl/ftp/ftp/upload"
          :limit="20"
          @fun="dealFiles"
          :IsDisabled="operation1"
          :IsDel="operation1"
        ></upload-files>
        <el-row v-if="operation1">
          <el-col :span="24" style="text-align: center">
        
              <el-button type="primary" v-on:click="onSubmit('SakaryForm')"
                >提交</el-button
              >
         
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog
    title="选择人员"
      :visible.sync="addUserVisible"
      v-model="addUserVisible"
      width="75%"
      center
      append-to-body
    >
      <UserChoose
        :data="choosedusers"
        :all="true"
        :single="false"
        @callback="usercallFunction"
      ></UserChoose>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import UserChoose from "../../components/UserChoose";
import UserChoosejob from "../../components/UserChoosejob";
import UploadFiles from "../../components/UploadFiles";
import {
  GetDimissionList,
  AddDimission,
  UpdateDimission,
  GetUserPostDptOrg,
  GetDimissionDetail,
        GetAtPresentContractInfo,
        DeleteDimissionInfo
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar, UserChoose, UserChoosejob, UploadFiles },
  data() {
    return {
      loading: true,
      searchVal: "",
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      key: 0,
      SakaryVisible: false,
      buttonListmsg: "",
      users: [],
      choosedusers: [],
      Intochoosedusers: [],
      operation: false, // true:新增, false:编辑
      operation1: true,
      addUserVisible: false,
      IntoUserVisible: false,
      buttonList: [],
      SakaryForm: {
        UserId: "",
        UserName: "",
        Post: "",
        Factory: "",
        Dpt: "",
        PostId: "",
        DimissionDate: "",
        Type: "",
        HireDate: "",
        ContractNature: "",
        MobilePhone: "",
        ContractSatr: "",
        PersonDebt: "",
        ContractEnd: "",
        ContractState: "",
        HouseFundsState: false,
        HouseFundsStartDate: "",
        HouseFundsEndDate: "",
        HouseFundsDate: "",
        HouseFundsCharge: "",
        InsuranceStatei: true,
        InsuranceReduceDate: "",
        InsuranceCharge: "",
        LeaveCategory: "",
        UnusualState: false,
        UnusualReason: "",
        LeaveReason: "",
        HouseFundsExplain: "",
        ContractExplain: "",
        TrainExplain: "",
        CertificateName: "",
        CertificateCharge: "",
        AccountState: true,
        PhoneChargeState: true,
        DormCharge: "",
        ClotheCharge: "",
        CertificatePerson: "",
        ContractData: "",
        FileCharge: "",
        RelationExplain: "",
        FinancialExplain: "",
        DebtSum: "",
        FileArry: [],
      },
      selectForm: {
        name: "",
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    usercallFunction(newdata) {
      if (newdata.length > 1) {
        this.$message({
          message: "不允许选择多人",
          type: "error",
        });
        return;
      }

      this.SakaryForm.UserId = newdata[0].Id;
      this.SakaryForm.UserName = newdata[0].UserNameClaim;
      GetAtPresentContractInfo({ userId: newdata[0].Id }).then((res) => {
        this.SakaryForm.ContractNature = res.data.response.ContractNature;
        this.SakaryForm.ContractSatr = res.data.response.StartDate;
        this.SakaryForm.ContractEnd = res.data.response.EndDate;
        this.SakaryForm.HireDate = res.data.response.HireDate;
        this.SakaryForm.MobilePhone = res.data.response.MobilePhone;
      });

      GetUserPostDptOrg({ userId: newdata[0].Id }).then((res) => {
        if (res.data.success) {
          this.SakaryForm.Factory = res.data.response.OrganizationName;
          this.SakaryForm.Dpt = res.data.response.DepartmentName;
          this.SakaryForm.Post = res.data.response.PostName;
          this.SakaryForm.DptId = res.data.response.DepartmentId;
          this.SakaryForm.JobType = res.data.response.PostWorkTypeId;
        } else {
          this.$message({
            message: "获取部门信息出错",
            type: "error",
            duration: 5000,
          });
        }
      });
      this.addUserVisible = false;
    },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.SakaryForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },

    chooseUser() {
      this.addUserVisible = true;
    },

    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        userName: this.selectForm.name,
      };

      this.$api.DimissionGetSubmitInfoPageList(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleExamine() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要审核的一行数据！",
          type: "error",
        });
        return;
      }

      this.$api.DimissionSaveState({ id: row.Id }).then((res) => {
        if (res.data.success) {
              this.$message({
                message: "审核成功",
                type: "success",
                duration: 5000,
              });
              this.permissionShowVisible = false;
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
                duration: 5000,
              });
            }
      });

      //  GetAtPresentContractInfo({userId: row.UserId}).then((res) => {
      //   this.SakaryForm.ContractNature = res.data.response.ContractNature;
      //    this.SakaryForm.ContractSatr =  res.data.response.StartDate;
      //     this.SakaryForm.ContractEnd =  res.data.response.EndDate;
      //      this.SakaryForm.HireDate=  res.data.response.HireDate
      //          this.SakaryForm.MobilePhone=  res.data.response.MobilePhone
      //  });
      this.operation = false;
      this.operation1 = true;
    },
    handleShow() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }

      GetDimissionDetail({ id: row.Id }).then((res) => {
        console.log(res);
        this.SakaryForm = res.data.response;
        this.key += 1;
        this.SakaryVisible = true;
      });

      //  GetAtPresentContractInfo({userId: row.UserId}).then((res) => {
      //   this.SakaryForm.ContractNature = res.data.response.ContractNature;
      //    this.SakaryForm.ContractSatr =  res.data.response.StartDate;
      //     this.SakaryForm.ContractEnd =  res.data.response.EndDate;
      //      this.SakaryForm.HireDate=  res.data.response.HireDate
      //          this.SakaryForm.MobilePhone=  res.data.response.MobilePhone
      //  });
      this.operation = false;
      this.operation1 = false;
    },
    handleAdd() {
      (this.SakaryForm = {
        UserId: "",
        UserName: "",
        Post: "",
        Factory: "",
        Dpt: "",
        PostId: "",
        DimissionDate: "",
        Type: "",
        HireDate: "",
        PersonDebt: "",
        ContractNature: "",
        MobilePhone: "",
        ContractSatr: "",
        ContractEnd: "",
        ContractState: "",
        HouseFundsState: false,
        HouseFundsStartDate: "",
        HouseFundsEndDate: "",
        HouseFundsDate: "",
        HouseFundsCharge: "",
        InsuranceStatei: true,
        InsuranceReduceDate: "",
        InsuranceCharge: "",
        LeaveCategory: "",
        UnusualState: false,
        UnusualReason: "",
        LeaveReason: "",
        HouseFundsExplain: "",
        ContractExplain: "",
        TrainExplain: "",
        CertificateName: "",
        CertificateCharge: "",
        AccountState: true,
        PhoneChargeState: "",
        DormCharge: "",
        ClotheCharge: "",
        CertificatePerson: "",
        ContractData: "",
        FileCharge: "",
        RelationExplain: "",
        FinancialExplain: "",
        DebtSum: "",
        FileArry: [],
      }),
        (this.key += 1);
      (this.operation = true), (this.SakaryVisible = true);
      this.operation1 = true;
      },
      // 删除
      handleDelete() {
          let row = this.currentRow;

          if (!row) {
              this.$message({
                  message: "请选择要删除的一行数据！",
                  type: "error",
              });
              return;
          }

          this.$confirm("确定要删除吗？", "提示", {}).then(() => {
              let para = {
                  Id: row.Id,
              };
              DeleteDimissionInfo(para).then((res) => {
                  if (res.data.success) {
                      this.$message({
                          message: "删除成功",
                          type: "success",
                          duration: 5000,
                      });
                      this.getData(false);
                  } else {
                      this.$message({
                          message: "删除失败",
                          type: "error",
                          duration: 5000,
                      });
                  }
              });
          });
      },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let para = Object.assign({}, this.SakaryForm);
            if (this.operation) {
              //新增
              AddDimission(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: "添加成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible = false;
                  this.getData();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            } else {
              //修改
              UpdateDimission(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: "修改成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible = false;
                  this.getData(false);
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            }
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },
    dealFiles(data) {
      console.log(data.backData);
      this.SakaryForm.FileArry = data.backData;
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.tableTitle {
  position: relative;
  margin: 0 auto;

  height: 1px;
  background-color: #d4d4d4;
  text-align: center;
  font-size: 16px;
  color: rgba(101, 101, 101, 1);
}
.midText {
  position: absolute;
  left: 50%;
  background-color: #ffffff;
  padding: 0 15px;
  transform: translateX(-50%) translateY(-50%);
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>

